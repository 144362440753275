<template>
  <div class="container">
    <div class="checkin">
      <el-row gutter="20">
        <el-col span="5">
          <div class="search-block">
            <div class="popcontext">
              <ul class="tab">
                <li >
                  <b style="font-size:16px;">企业合作</b>
                </li>
                <li
                  rel="hgroup-booking"
                  @click="selectPannel('li_booking')"
                  class="active"
                  id="li_booking"
                >
                  <span>API合作</span>
                </li>
                <!--<li rel="hgroup-cuss" id="ga_cn_ybdj"><span>预办登机</span></li>-->
                <li rel="hgroup-aoc" id="li_aoc" @click="selectPannel('li_aoc')">
                  <span>运航货运</span>
                </li>
              </ul>
            </div>
          </div>
        </el-col>
        <el-col span="19">
          <div class="right_pannel">
            <div style="width:100%;overflow:hidden; height:300px;">
              <img src="../../assets/3.jpg" />
            </div>
            <div
              style="height:300px;  background-color:#ffffff;border-radius:5px;position:absolute;top:160px;left:180px; "
            >
              <div class="service-query">
                <div>
                  <h3 class="service-title">湖南航空货运子系统</h3>
                  <p class="service-prompt">* 我们在部分航线上提供预选座位服务，但需在值机时间开放后重新办理值机手续。</p>
                </div>
                <ul class="service-forms">
                  <li class="form-group">
                    <label class="form-label" for="certNo">企业号：</label>
                    <el-input
                      id="certNo"
                      v-model="input"
                      placeholder="请输入证件号/票号"
                      style="width:300px"
                    ></el-input>
                  </li>
                  <li class="form-group">
                    <label class="form-label" for="passengerName">员工账号：</label>
                    <el-input
                      id="certNo"
                      v-model="input"
                      placeholder="请输入乘机人姓名"
                      style="width:300px"
                    ></el-input>
                  </li>
                  <li class="form-group">
                    <label class="form-label" for="mobile">密码：</label>
                    <el-input id="certNo" v-model="input" placeholder="请输入手机号" style="width:300px"></el-input>
                  </li>
                  <li>
                    <div class="checkin-links agree-protocol query">
                      <el-checkbox>已阅读</el-checkbox>
                      <a class="link link-sm" href="/doc/detail/10" target="_blank">【网上值机协议】</a>
                      <a
                        class="link link-sm"
                        href="/doc/detail/3"
                        target="_blank"
                      >【关于旅客行李中锂电池运输的旅客须知】</a>
                      <a class="link link-sm" href="/doc/detail/11" target="_blank">【常见问题】</a>
                      <a class="link link-sm" href="/doc/detail/12" target="_blank">【使用手册】</a>
                      <a class="link link-sm" href="/doc/detail/11" target="_blank">【常见问题-开放时间】</a>
                    </div>
                  </li>
                  <li>
                    <div style="text-align:center;width:100%;padding-bottom:5px">
                      <el-button type="danger" style="width:300px;">立即登录</el-button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div style="margin-top:210px;height:400px">
              <div class="warm-prompt">
                <h3>温馨提示</h3>
                <div>
                  <p>1 目前网上值机办理开放时间为：航班计划起飞前一日下午18点至航班起飞前90分钟。如不能正常办理值机或办理值机失败，请您稍后再次尝试或乘机当日提前到达机场值机柜台办理；</p>
                  <p style="text-indent: 24px;">详情见：【常见问题-开放时间】</p>
                  <p>2 目前暂不支持国际及地区航班的网上值机业务；</p>
                  <p>3 请核实您的购票姓名、购票凭证号码及出发城市，请查看航班信息与值机状态，确认无误再值机；</p>
                  <p>4 目前网上值机服务暂不支持病患、担架、残疾、孕妇、军警残、无成人陪伴儿童、婴儿、带婴儿成人等特殊旅客，请到机场人工柜台办理；</p>
                  <p>5 如您为儿童办理了网上值机，请先取消儿童值机后再取消成人值机；</p>
                  <p>6 此为湖南航空官方值机办理渠道，若您使用第三方服务办理值机，湖南航空将无法保障您的后续服务；</p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      input: ""
    };
  },
  methods: {
    selectPannel(pannelId) {
     // console.log(pannelId);
      document.getElementById("li_booking").setAttribute("class", "");
      document.getElementById("li_aoc").setAttribute("class", "");
      document.getElementById(pannelId).setAttribute("class", "active");
    }
  }
};
</script>
<style scoped>
.checkin {
  margin-top: 20px;
  margin-bottom: 20px;
}
.left_pannel {
  height: 500px;
  background-color: #f7f7f7;
  overflow: hidden;
}
.right_pannel {
  height: 800px;
  position: relative;
  background-color: #f7f7f7;
  border: 1px solid #ededed;
}
.col_menu b {
  font-size: 24px;
  margin: 20px 20px 0;
  color: #333;
  display: block;
  font-weight: normal;
  border-bottom: 1px solid #f1f1f1;
}
.col_menu ul {
  margin-bottom: 20px;
}
ul,
ol,
dl,
dd,
dt {
  margin: 0;
  padding: 0;
  display: block;
}
.col_menu li {
  margin: 10px 0;
  position: relative;
}
.col_menu li.current {
  background: none;
}
.col_menu li.current a:link,
.col_menu li.current a:visited,
.col_menu li.current a:active {
  border-left: 2px #ff6600 solid;
  color: #666;
  font-weight: bold;
}
.col_menu li a:link,
.col_menu li a:visited,
.col_menu li a:active {
  display: block;
  line-height: 30px;
  padding: 0 55px 0 20px;
}
a:link,
a:visited,
a:active {
  color: #666;
  text-decoration: none;
}

a {
  color: #337ab7;
  text-decoration: none;
}
.warm-prompt {
  padding: 40px 50px 80px;
}
.warm-prompt p {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #9d9d9d;
  text-align: left;
}

.service-query {
  position: relative;
  z-index: 10;
  margin: 0 auto;
  padding: 8px 44px 0;
  width: 578px;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  /* -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05); */
}
.service-query .service-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 55px;
  color: #4d4e4e;
  text-align: center;
  border-bottom: 1px solid #eae7e7;
}
.service-query .service-prompt {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  color: #9d9d9d;
  text-align: center;
}
.service-query .service-forms > li {
  margin-top: 20px;
}

.form-group .form-label {
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #4d4e4e;
  text-align: right;
}
.checkin-links.query {
  margin: 24px 35px 24px 75px !important;
}

.agree-protocol {
  margin: 24px 0;
  padding-left: 24px;
  font-size: 0;
}
.link.link-sm {
  height: 18px;
  font-size: 12px;
  line-height: 18px;
}

.link {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2c6e8b;
  text-align: left;
}

.search-block {
  width: 1200px;
  margin: auto;
  position: relative;
}
.popcontext .tab {
  z-index: 1000;
  width: 240px;
}
.popcontext .tab li {
  height: 60px;
  line-height: 60px;
  /* border-top: 1px solid #4e617f; */
  background-color: #f5f5f5;
  color: #000000;
  font-size: 14px;
  text-align: center;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: alpha(Opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
}


/* border-bottom: 1px solid #d6003a; */
.popcontext .tab li:nth-child(2) {
  border-top: 1px solid #d6003a;
}

.popcontext .tab li:last-child {
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
}


.popcontext .tab li:nth-child(2).active {
  border-top: 1px solid #f9f9f9;
}
.popcontext .tab li.active {
  /* border-top: 1px solid #fff; */
  background-color: #d6003a;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-left: 0;
  padding-left: 10px;
  border-right: 1px solid #fff;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  filter: alpha(Opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}
.popcontext hgroup {
  display: none;
  position: absolute;
  width: 460px;
  top: -410px;
  left: 0px;
  z-index: 1000;
  margin-left: 140px;
  background-color: #fff;
  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}
.popcontext hgroup article {
  position: relative;
  height: 364px;
  padding-left: 30px;
}
</style>
